import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BasesService {

  private server:string;
  constructor() { 
    this.server = "http://phpoutils.eu-west-2.elasticbeanstalk.com/api/";
  }

  getServer(){
    return this.server;
  }
}
