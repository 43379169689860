import { Injectable } from '@angular/core';
import { BasesService } from './bases.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BasesService{

  constructor(private http:HttpClient) {
    super();
  }

  sendMessage(query:any){
    return this.http.post(this.getServer() + 'email/sendmail' , JSON.stringify(query));
  }
}
